import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from './components/LoginPage';
import CreateOrderPage from './components/CreateOrderPage';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
  <>
    <Router>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="createOrder" element={<CreateOrderPage />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
