import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { BsCloudUpload } from 'react-icons/bs';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.mode === 'dark' ? '#111' : '#EEE'};
    color: ${props => props.theme.mode === 'dark' ? '#EEE' : '#111'};
  }
`;

const StyledCard = styled(Card)`
  background-color: ${props => props.theme.mode === 'dark' ? '#333' : '#FFF'};
  color: ${props => props.theme.mode === 'dark' ? '#EEE' : '#111'};
  border: 2px solid ${props => props.theme.mode === 'dark' ? '#555' : '#CCC'};
`;

const StyledCardHeader = styled(Card.Header)`
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: ${props => props.theme.mode === 'dark' ? '#333' : '#FFF'};
  color: ${props => props.theme.mode === 'dark' ? '#EEE' : '#111'};
  border-bottom: 2px solid ${props => props.theme.mode === 'dark' ? '#555' : '#CCC'};
`;

const CenteredButton = styled(Button)`
  display: block;
  width: 60%; // Adjust this to the desired width
  margin: auto;
`;

const theme = {
  mode: "dark"
}

function CreateOrderPage() {
  const [file, setFile] = useState();
  const [user, setUser] = useState({});
  const navigate = useNavigate();

  useEffect(() => {

    const verifyToken = async () => {
      const token = localStorage.getItem('token');
      const res = await fetch('http://localhost:4000/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
      
      if (res.ok) {
        const { username, email, balance } = await res.json();
        setUser({ username, email, balance });
      } else {
        navigate('/');
      }
    }

    verifyToken();
  }, [navigate]);


  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFile(Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0])
      }));
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Row className="justify-content-md-center align-items-center vh-100">
          <Col xs={12} md={6}>
            <StyledCard>
                <StyledCardHeader>
                    <h4>User Info</h4>
                    <p>Name: {user.username}</p>
                    <p>Email: {user.email}</p>
                    <p>Balance: {user.balance}</p>
                </StyledCardHeader>
              <Card.Body>
                <h4 className="text-center mb-4">Start an Order</h4>
                <div {...getRootProps({ className: 'dropzone', style: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' } })}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop some files here, or click to select files</p>
                <BsCloudUpload size={50} style={{cursor: 'pointer'}}/>
                </div>
                <aside>
                  {file && <img src={file.preview} alt="preview" />}
                </aside>
                {file && <CenteredButton variant="primary" className="mt-4">Start An Order</CenteredButton>}
              </Card.Body>
            </StyledCard>
          </Col>
        </Row>
      </Container>
    </ThemeProvider>
  );
}

export default CreateOrderPage;
