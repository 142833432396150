import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Container, Row, Col } from 'react-bootstrap';
import { FaUser, FaLock } from 'react-icons/fa';
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.mode === 'dark' ? '#111' : '#EEE'};
    color: ${props => props.theme.mode === 'dark' ? '#EEE' : '#111'};
  }
`;

const StyledFormControl = styled(Form.Control)`
  background-color: ${props => props.theme.mode === 'dark' ? '#333' : '#FFF'};
  color: ${props => props.theme.mode === 'dark' ? '#EEE' : '#111'};
  max-width: 300px;
  margin: 0 auto;
`;

const StyledButton = styled(Button)`
  max-width: 300px;
  display: block;
  margin: 12px auto;
`;

const StyledForm = styled(Form)`
  .form-group {
    margin: 21px 0;
  }
  .form-label {
    padding-top: 6px; // Added 6px padding to top of labels
  }
`;

const CenteredLabel = styled(Form.Label)`
  text-align: center;
  display: block;
`;

const theme = {
  mode: "dark"
}

function LoginPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleLogin = async () => {
    
    try {
      const res = await fetch('http://localhost:4000/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });
  
      if (!res.ok) {
        const {message} = await res.json();
        alert(message);
        throw new Error('Login failed.');
      }
      
      const { token } = await res.json();
      localStorage.setItem('token', token);
      navigate('/createOrder');

    } catch (error) {
      console.error(error);
    }
    
  };

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Container>
        <Row className="justify-content-md-center align-items-center vh-100">
          <Col xs={12} md={6}>
            <h1 className="text-center mb-4">Eat Good</h1>
            <StyledForm>
              <Form.Group controlId="formBasicEmail" >
                <CenteredLabel><FaUser /> Username</CenteredLabel>
                <StyledFormControl type="text" placeholder="Username" value={username} onChange={e => setUsername(e.target.value)} />
              </Form.Group>

              <Form.Group controlId="formBasicPassword">
                <CenteredLabel><FaLock /> Password</CenteredLabel>
                <StyledFormControl type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
              </Form.Group>
              <StyledButton variant="primary" onClick={handleLogin}>
                Login
              </StyledButton>
            </StyledForm>
          </Col>
        </Row>
      </Container>
    </ThemeProvider>
  );
}

export default LoginPage;
